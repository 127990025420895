import ViewModel from '@/models/ViewModel'
import SystemEventChange from './SystemEventChange'
import { camelCase } from 'lodash-es'
import ISystemEvent from './ISystemEvent'

export default class SystemEvent extends ViewModel implements ISystemEvent {
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  concurrency: string
  transactionId: string
  eventTime: string
  eventType: string
  eventName: string
  subject: string
  internalSubject: string
  topic: string
  description: string
  comment: string
  dataVersion: string
  metadataVersion: string
  data: string
  displayName: string
  changes: SystemEventChange[] = []

  constructor(systemEvent: ISystemEvent) {
    super()
    Object.assign(this, systemEvent)
    this.modifiedDate = systemEvent.eventTime || systemEvent.modifiedDate

    const data = JSON.parse(systemEvent.data)
    const oldValues = data.OldValues
    const newValues = data.NewValues

    for (const key in oldValues) {
      const oldValue = oldValues[key]
      const newValue = newValues[key]

      const field = camelCase(key)

      const subject = this.getSubject(field)

      const change = new SystemEventChange()
      change.modifiedDate = systemEvent.modifiedDate
      change.description = field
      change.subject = subject
      change.action = systemEvent.eventName
      change.oldValue = oldValue
      change.newValue = newValue

      if (change.oldValue !== change.newValue) {
        this.changes.push(change)
      }

      if (change.action !== 'Updated') {
        break
      }
    }
  }

  private getSubject(field: string) {
    let subject = this.subject.replace(/\/Listings\/([a-z,0-9,-]*)\/?/, '')
    if (subject) {
      subject += '/'
    }
    subject += field
    return subject
  }
}
