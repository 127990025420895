






import EventsTable from '../components/EventsTable.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    EventsTable
  }
})
