import ViewModel from '@/models/ViewModel'
import Formatter from '@/utils/Formatter'

export default class SystemEventChange extends ViewModel {
  action: string
  description: string
  hidden = false
  modifiedDate: string
  newValue: any
  oldValue: any
  subject: string

  get oldValueFormatted() {
    return Formatter.autoFormat(this.oldValue) || 'null'
  }

  get newValueFormatted() {
    return Formatter.autoFormat(this.newValue) || 'null'
  }
}
